.ad-unit-sponsored-bar {
    background-color: #f2f2f2;

    .n_columns.FinanceInsuranceAd {
        background: #fff;
        border: 1px solid #ccc;

        .n_columns {
            .n_align-middle {
                border: none;
                margin-top: 15px;
                margin-left: 15px;
                float: left;

                &.n_width-max {
                    position: absolute;
                    top: 12px;
                    left: 75px;
                    margin-top: 0;
                    margin-left: 20px;
                    width: 180px;
                }
            }
        }

        &:hover {
            background: #eee;
        }
    }

    .FinanceInsuranceAd {
        position: relative;
        width: 300px;
        min-height: 100px;
        height: 100px !important;
        border-color: #fff !important;
        transition: .3s background-color ease-in-out;

        & > div {
            &.n_md-show {
                display: none;
            }
        }

        .n_align-middle {
            width: 75px;
            padding: 0 !important;

            img {
                width: 68.5px;
                height: 68.5px;
                border: .5px solid #e5e5e5;
                border-radius: 50px;
                box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
            }
        }

        &__title {
            text-align: left;
            color: #333;
            font-weight: 700;
            line-height: 1.25;
            font-size: 16px;
        }

        &__description {
            color: #000;
            font-size: 13px;
            text-align: left;
            line-height: 1.25;
            margin-top: 5px;
        }

        &:hover {
            background-color: #e6e6e6 !important;
        }
    }
}
