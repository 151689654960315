.ad-unit {
    margin: 0 auto;

    &-primary {
        background: transparent;
    }

    &-secondary {
        background: #f2f2f2;
    }

    &-text {
        color: #808080;
        font-size: 12px;
        font-weight: 400;
        text-align: right;
        margin-top: 10px;
    }

    &-top-leaderboard {
        display: flex;
        justify-content: center;
        width: 100%;
        line-height: 0;
        padding: 0;
        transition: all .2s linear;

        &.ad-loaded {
            padding: 10px 0;
            transition: all .2s linear;
        }

        > .advert {
            overflow: hidden;
            line-height: 0;
        }
    }

    &-horizontal {
        position: relative;
        margin: 18px auto;
        overflow: hidden;
    }

    &-sponsored-bar {
        display: flex;
        width: 100%;
        margin: 20px 0;

        .ad-container {
            position: relative;
            margin: 0 auto;
            overflow: hidden;

            > span {
                position: absolute;
                left: 0;
                top: 10px;
                padding: 0 10px;
                font-size: 10px;
                color: #333;
            }

            .bar {
                display: flex;

                > * {
                    margin: 30px 0 35px;
                }

                > *:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }

    &-hidden {
        overflow: hidden;
        height: 0;
    }
}

.ad-unit-unmissable-top-sticky-banner {
    top: 0;
    position: fixed;
    z-index: 9999;
    width: 100%;
  
    .unmissable-wrapper {
        display: none;
        justify-content: flex-end;
        width: 100px;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 9999;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0) 104%);
    }
    .unmissable-close-button {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        animation: fadeIn ease 1.5s;
        -webkit-animation: fadeIn ease 1.5s;
        -moz-animation: fadeIn ease 1.5s;
        -o-animation: fadeIn ease 1.5s;
        -ms-animation: fadeIn ease 1.5s;
    }
    .icon-close {
        font-size: 24px;
        font-weight: 600;
        color: white;
    }
    @-webkit-keyframes fadeIn {
        0% { opacity: 0; }
        80% { opacity: 0; }
        100% { opacity: 1; }
    }

    @keyframes fadeIn {
        0% { opacity: 0; }
        80% { opacity: 0; }
        100% { opacity: 1; }
    }

}

body {
    &.unmissable {
        top: 50px;
        right: 0;
        left: 0;
        position: absolute;
    }
}

#cs-nav {
    &.unmissable {
        top: 50px;
    }

    .site-nav {
        &.unmissable:before {
            top: 135px;
        }
    }
}

.chileautos {
    #sticky_footer {
        direction: ltr;
        z-index: 2147483645;
        position: fixed;
        bottom: 0;
        width: 100%;
        transition: transform .3s linear, height .3s linear;
        left: 0;
    }
}
